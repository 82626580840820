@import '~bootstrap/scss/bootstrap';
.App {
  h1{text-align: center;}
  section:not(#ContactMe){
    margin-bottom:2em;
  }
  .spyNav {
    right:0;
    z-index: 10;
    ul {
      border-radius: 50%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      margin-left: 0;
      padding-left: 1em;
      border-color: $gray-600;
      a {
        padding-left: 0;
        padding-right: 1rem;
        font-family: 'Oswald', sans-serif;
        text-align: right;
        svg{
          float:right;
        }
        @media (pointer: fine) {
          &:hover {
            cursor: pointer;

            span {
              width: 60px;
            }
          }
        }
      }
    }
  }
  &.mode-left-hand{
    .NavMenu button.buttonMenu{
      left:0;
      right:100%;
    }
    .logo{
      width:370px;
      height:100%;
      text-align: right;
      padding-right: 1rem;
      position: absolute;
      right:0;
      &:hover{
        padding-right: 0;
      }
    }
    .spyNav {
      left:1em;
      ul {
        border-radius: 50%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        border-right: 2px solid;
        margin-left: 0;
        padding-left: 0;
        border-color: $gray-600;

        a {
          text-align: left;
          padding-left: 0;
          padding-right: 1rem;
          font-family: 'Oswald', sans-serif;
          svg{
            float:none;
          }
        }
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer {
  background-color: #E5E5E5;
  padding:4rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinner{
  animation: App-logo-spin infinite 2s linear;
}
