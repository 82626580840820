.mouseSpy{
    /* one or more available input mechanism(s) can hover over elements with ease */
    @media (pointer: coarse) {
        display:none!important;
    }

    border-radius: 50%;
    height: 30px;
    width:30px;
    margin-left: -10px;
    margin-top: -10px;
    border: 2px solid;
    border-color: #282c34;
    z-index: 1;
    //opacity: 0.2;
    transition-delay: 0ms;
    transition: width .2s, top 100ms, left 100ms;
    //transition: top 45ms linear,left 45ms linear,-webkit-transform .25s cubic-bezier(.72,.19,.28,.94);
    //transition: transform .25s cubic-bezier(.72,.19,.28,.94),top 45ms linear,left 45ms linear;

    &.active{
        height: 50px;
        width: 50px;
        margin-left: -30px;
        margin-top: -30px;
        border-color: #F0AB15;
    }

}