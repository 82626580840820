.NavMenu {
    z-index:100;
    @media all and (max-width: 767px) {
        height: 3.2rem;
    }
    .logo {
        padding-top: .25rem;
        color: rgba(0, 0, 0, 0.87);
        display: inline-block;
        font-size: 2rem;

        @media ALL and (max-width:450px) {
            &.mouseEnter {
                font-size: 1.55rem;
                padding-top: .55rem;
            }
        }

        span {
            transition: .5s ease;
            display: inline-block;
            &.train{
                transition: .05s ease;
                opacity: 0;
                position: relative;
                left: -1.0em;
            }
        }
        &:hover{
            text-decoration: none;
            span{
                -ms-transform: rotate(120deg);
                -ms-transform-origin: 8% 70%;
                transform: rotate(220deg);
                transform-origin: 11% 80%;
                opacity: 0;
                &:last-child{
                    -ms-transform: rotate(150deg);
                    -ms-transform-origin: 10% 80%;
                    transform: rotate(200deg);
                    transform-origin: 11% 80%;
                }
                &.train{
                    transform:none;
                    opacity: 1;
                }
            }
        }
    }
    z-index: 9999;
    padding-left: 1rem;
    width:100%;
    button.buttonMenu{
        position: absolute;
        right:1rem;
        background: white;
        min-width:50px;
    }
}

.fullList {
    padding-top: 60px;
    &.fullList-Right{
        .MuiListItemIcon-root{
            float: right;
            margin-left: .5em;
            min-width: 25px;
            padding-right: 1em;
        }
        .MuiListItem-button{text-align: right;}
    }
}
