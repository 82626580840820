
.animated-icon {
    position: relative;
    width: 38px;
    height: 38px;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    display: inline-block;
}
.animated-icon .ani {
    top: 18.5px;
    left: 5px;
}
.animated-icon .ani,
.animated-icon .ani:before,
.animated-icon .ani:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 28px;
    background: rgb(33, 33, 33);
    -webkit-transition: .3s;
    transition: .3s;
}
.animated-icon .ani:before,
.animated-icon .ani:after {
    background: inherit;
}
.animated-icon.menu-arrow-r.anim {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.animated-icon.menu-arrow-r .ani:before {
    top: -8px;
    left: 0;
}
.animated-icon.menu-arrow-r .ani:after {
    top: 8px;
    left: 0;
}
.animated-icon.menu-arrow-r.anim .ani:before {
    top: -5.5px;
    width: 18px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: -3px;
}
.animated-icon.menu-arrow-r.anim .ani:after {
    top: 5.5px;
    width: 18px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -3px;
}
.animated-icon.menu-arrow-l.anim {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.animated-icon.menu-arrow-l .ani:before {
    top: -8px;
    right: 0;
}
.animated-icon.menu-arrow-l .ani:after {
    top: 8px;
    right: 0;
}
.animated-icon.menu-arrow-l.anim .ani {
    width: 27px;
}
.animated-icon.menu-arrow-l.anim .ani:before {
    top: -5.5px;
    width: 18px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -4px;
}
.animated-icon.menu-arrow-l.anim .ani:after {
    top: 5.5px;
    width: 18px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: -4px;
}
.animated-icon.menu-cross .ani:before {
    top: -8px;
    right: 0;
}
.animated-icon.menu-cross .ani:after {
    top: 8px;
    right: 0;
}
.animated-icon.menu-cross.anim .ani {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.animated-icon.menu-cross.anim .ani:before {
    top: 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.animated-icon.menu-cross.anim .ani:after {
    top: 0;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
.animated-icon.menu-cross-s .ani:before {
    top: -8px;
    left: 0;
}
.animated-icon.menu-cross-s .ani:after {
    top: 8px;
    left: 0;
}
.animated-icon.menu-cross-s.anim .ani {
    width: 0;
}
.animated-icon.menu-cross-s.anim .ani:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.animated-icon.menu-cross-s.anim .ani:after {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}