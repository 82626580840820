.Resume {
    main{
        //height: 100vh;
        >div{
           // height:0;
           //position: relative;
           // z-index: -1;
           // opacity: 0;
           // -webkit-transition: opacity 500ms;
           // transition: opacity 500ms;
            section {
           //     display: none;
           //     position: absolute;
           //     top:50px;
           //     width: 100%;
           //
                min-height: 100vh;
                li{
                    margin: 0 0 2em 0;
                }
            }
            //&.active{
            //    height:100%;
            //   z-index: 1;
            //    opacity: 1;
            //    section {
            //        display: block;
            //    }
            //}
        }
    }

}