//@import url('https://fonts.googleapis.com/css?family=Archivo+Black|Lato:400,700|Roboto+Mono:400,400i&display=swap');
//$font-family-base: 'Archivo Black', sans-serif;
//$font-family-monospace: 'Roboto Mono', monospace;
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Lato|Oswald:500|Roboto+Mono:400,700&display=swap');
$font-family-monospace: 'Roboto Mono', monospace;
$font-family-base: 'Oswald', sans-serif;
//$font-size-base: 1rem;
$gray: #efefef;
$secondary: #f0ab15;
@import '~bootstrap/scss/bootstrap';

.border-dotted{
    border-bottom-style: dashed!important;
}

body{
    font-family: $font-family-monospace;
    cursor:crosshair;
    .logo{
        font-family: 'Fjalla One', sans-serif;
        font-size: xx-large;
    }
    .zoom{
        &:hover{
            transform: scale(1.25)!important;
            transition: all 0.3s ease-in-out 0.2s;
            cursor: nw-resize;
        }
    }
    //overflow: hidden;
    h1{
        text-transform: uppercase;
        font-weight: 700;
        font-family: $font-family-base;
        font-size: 3rem;
        color: #f0ab15;
        padding:.75em 0;
    }
    h2{
        font-family: $font-family-base;
        font-weight: 400;
    }
    nav{
        position: fixed;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 999999999999;
        ul{
            border: 1px solid;
            display: flex;
            flex-direction: column;
            a{
                -webkit-transition: width 2s; /* Safari prior 6.1 */
                transition: width 2s;
                display: block;
                padding: .5em;
                z-index: 99999999999;
                svg{
                    display: inline-block;
                    z-index: 99999999999;
                }
                span{
                    width: 0;
                    display: inline-block;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s linear 0ms, opacity 200ms, width 100ms;
                }
                &.active, &:hover{
                    font-weight: 700;
                    color: #f0ab15!important;
                    text-decoration: underline;
                }
                @media (pointer: fine) {
                    &:hover {
                        cursor: pointer;

                        span {
                            width: 80px;
                            visibility: visible;
                            opacity: 1;
                            transition: visibility 0s linear 0s, opacity 600ms, width 100ms;
                        }
                    }
                }

                    &:active {
                        cursor: pointer;

                        span {
                            width: 80px;
                            visibility: visible;
                            opacity: 1;
                            transition: visibility 0s linear 0s, opacity 600ms, width 100ms;
                        }
                    }

            }
        }
    }
    main.container-fluid{


    }
    section{
        background-color: white;
        padding: 2em;
    }


    dd,dt{
        margin-bottom:1em;
    }
    dt{
        text-transform: uppercase;
        font-size: 1.1rem;
    }
}
